import Grid from '@mui/material/Grid'
import { Link, Typography } from '@mui/material'
import React from 'react'

const FlotteladenImprint = () => {
  return (
    <Grid container>
      <Grid item xs={12} sx={{ marginBottom: 2 }}>
        <Typography variant={'h2'}>
          Flotteladen
        </Typography>
        <Typography variant={'h2'}>
          Hectronic Cloud Labs GmbH
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: 2 }}>
        <Typography>
          Kaltbrunner Straße 24a
        </Typography>
        <Typography>
          D-78476 Allensbach
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography>
          Telefon:
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography>
          +49-(0) 7533 80 30 595
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography>
          E-Mail:
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Link
          component="a"
          to="_blank"
          onClick={(e) => {
            window.location.href = `mailto:info@flotteladen.de`
            e.preventDefault()
          }}
        >
          info@flotteladen.de
        </Link>
      </Grid>
      <Grid item xs={4}>
        <Typography>
          Internet:
        </Typography>
      </Grid>
      <Grid item xs={8} sx={{ marginBottom: 2 }}>
        <Link
          component="a"
          href="https://www.flotteladen.de/"
          target="_blank">
          www.flotteladen.de
        </Link>
      </Grid>
      <Grid item xs={12}>
        <Typography sx={{ marginBottom: 2, fontWeight: 700 }}>
          Umsatzsteuer-Identifikationsnummer gemäß §27a Umsatzsteuergesetz:
        </Typography>
        <Typography sx={{ marginBottom: 2 }}>
          DE367933461
        </Typography>
        <Typography sx={{ marginBottom: 2, fontWeight: 700 }}>
          Amtsgericht Freiburg i. Br. HRB 731004 Geschäftsführer:
        </Typography>
        <Typography sx={{ marginBottom: 2 }}>
          Stefan Forster
        </Typography>
        <Typography sx={{ marginBottom: 2, fontWeight: 700 }}>
          Inhaltlich Verantwortlicher gemäß § 10, Abs. 3 MDStV:
        </Typography>
        <Typography sx={{ marginBottom: 2 }}>
          Stefan Forster, Kaltbrunner Strasse 24a, D-78476 Allensbach
        </Typography>
        <Typography sx={{ marginBottom: 2, fontWeight: 700 }}>
          Haftungsausschluss
        </Typography>
        <Typography sx={{ marginBottom: 2 }}>
          Die Hectronic Cloud Labs GmbH bemüht sich, auf dieser Website richtige und vollständige Informationen zur Verfügung zu stellen. Sie übernimmt jedoch keine Haftung oder Garantie
          für die Aktualität,
          Richtigkeit und Vollständigkeit der bereitgestellten Informationen, da trotz aller Sorgfalt ein Auftreten von Fehlern nicht grundsätzlich ausgeschlossen werden kann. Die gesetzliche Haftung
          für Vorsatz und/oder grobe Fahrlässigkeit bleibt davon unberührt. Die Hectronic Cloud Labs GmbH behält sich das Recht vor, ohne vorherige Ankündigung Änderungen, Ergänzungen oder
          Löschungen der
          bereitgestellten Informationen vorzunehmen.
        </Typography>
        <Typography sx={{ marginBottom: 2, fontWeight: 700 }}>
          Externe Links und Verweise
        </Typography>
        <Typography sx={{ marginBottom: 2 }}>
          Die Hectronic Cloud Labs GmbH übernimmt keine Haftung oder Garantie für die Gestaltung oder den Inhalt von Internetseiten, auf die unsere Website direkt oder indirekt verweist.
          Auf Aktualität oder
          Inhalte fremder Websites hat die Hectronic Cloud Labs GmbH keinerlei Einfluss. Besucher folgen Verbindungen zu anderen Websites und Homepages auf eigene Gefahr und benutzen sie
          gemäß den jeweils
          geltenden Nutzungsbedingungen der entsprechenden Websites. Für Schäden, die aus der Nutzung dieser Websites resultieren wird keine Haftung übernommen. Die Hectronic Cloud Labs GmbH distanziert sich
          von
          den Inhalten anderer Websites und macht sich diese auch nicht zu Eigen, selbst wenn die Links durch uns gesetzt wurden.
        </Typography>
        <Typography sx={{ marginBottom: 2, fontWeight: 700 }}>
          Urheberrechte
        </Typography>
        <Typography sx={{ marginBottom: 2 }}>
          Der Inhalt und das Layout dieser Website sind urheberrechtlich geschützt. Aus Gründen des Urheberrechts ist die Speicherung und Vervielfältigung von Texten, Bildmaterial oder Grafiken aus
          dieser Website grundsätzlich nicht gestattet, es sei denn dies wurde ausdrücklich zuvor schriftlich genehmigt. Lediglich ausdrücklich autorisierte und durch Download zugänglich gemachte
          Informationen mit Quellenangabe sind hiervon ausgenommen. Allein zu privaten und nicht kommerziellen Zwecken ist eine Vervielfältigung oder Speicherung der Website gestattet. Die Einbettung
          der Website in andere Onlineauftritte oder in Teilfenster ist nur nach vorheriger ausdrücklicher und schriftlicher Genehmigung durch die Hectronic Cloud Labs GmbH erlaubt.
        </Typography>
      </Grid>
    </Grid>
  )
}

export default FlotteladenImprint